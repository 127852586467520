.carousel-container {
  margin-bottom: 20px;
}
.selected-image {
  width: 100%;
  height: 450px;
  margin-bottom: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carousel__button {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  height: 30px;
  z-index: 2;
}

.carousel__button:hover {
  transform: scale(1.08);
  transition: transform 150ms;
  z-index: 2;
}

.carousel__button-left {
  left: 10px;
}

.carousel__button-right {
  right: 10px;
}

.carousel__button img {
  width: 30px;
  border: none;
  opacity: 1;
  mix-blend-mode: overlay;
  background: #fff;
  border-radius: 50%;
}

@media screen and (max-width: 1200px) {
  .selected-image {
    height: 80rem;
  }
}
@media screen and (max-width: 600px) {
  .selected-image {
    height: 100rem;
  }
}

.carousel {
  position: relative;
  /* border: 1px solid #999; */
}

.carousel__images {
  display: flex;
  max-width: 100%;
  overflow-x: overlay;
}

.carousel__image-selected {
  border: 3px solid #1890ff !important;
}

.carousel__image {
  margin-right: 10px;
  height: 150px;
  min-width: 150px;
  border: 3px solid #ffa70000;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #999;
  cursor: pointer;
}

.carousel__image:hover {
  border: 3px solid #cbe6ff;
}
