@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");
* {
  border-collapse: collapse;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;

  font-family: "Pretendard", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 0.5vw;
  scroll-behavior: smooth;
}
@media screen and (max-width: 1200px) {
  html,
  body {
    font-size: 0.7vw;
  }
}
@media screen and (max-width: 600px) {
  html,
  body {
    font-size: 0.8vw;
  }
}
a {
  text-decoration: none;
  color: #444;
}
a:link {
  text-decoration: none;
  color: #444;
}
a:visited {
  text-decoration: none;
  color: #444;
}
a:hover {
  text-decoration: none;
  color: #444;
}
a:active {
  text-decoration: none;
  color: #444;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 90);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

.ant-menu-submenu-popup {
  /* width: 100%; */
  /* text-align: center; */
}

@media screen and (max-width: 1200px) {
  .ant-menu-submenu-popup {
    width: 30%;
    right: 35%;
    left: auto !important;
  }
}
@media screen and (max-width: 600px) {
  .ant-menu-submenu-popup {
    width: 50%;
    right: 25%;
    left: auto !important;
  }
}

.root_daum_roughmap {
  width: 100%;
}

.root_daum_roughmap_landing {
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .root_daum_roughmap {
    width: 90% !important;
  }
}
@media screen and (max-width: 600px) {
  .root_daum_roughmap {
    width: 90% !important;
  }
}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: #fff !important;
  z-index: 10;
  position: relative;
  border: 1px solid #eee;
  border-radius: 10px;
  box-shadow: 0 0px 20px #00000026;
}

.ant-menu-submenu-placement-rightTop ul {
  transform: translateY(40px) !important;
}

.ant-menu-vertical .ant-menu-submenu-title {
  padding-inline: 16px !important;
}

.ant-menu-submenu-placement-rightTop {
  background-color: #00000000;
}
